import React from "react"

/**
 * Material
 */
import { EtcAccepted } from "../../../components/contact/etc/accepted"

const breadcrumbsData: { text: string; path: string }[] = [
  { text: `CONTACT`, path: `contact` },
  { text: `その他`, path: `contact/etc` },
]

/**
 * Component
 */
export default function Home() {
  return (
    <EtcAccepted breadcrumbsData={breadcrumbsData} />
  )
}